

<template>
	<phi-page color="#f3f3f3">
		<div slot="toolbar">
            <h1>{{ $t("launcher.classroom") }}</h1>
		</div>

		<div>
            <section>
                <div class="classroom phi-card" v-if="childrens">

                    <router-link
                        v-for="children in childrens"
                        :key="children.person.id"
                        :to="'/people/'+children.person.id+'/classroom'"
                        class="block"
                        >

					<PersonItem :person="children.person" class="ui-clickable" />
                        
                    </router-link>
                </div>
            </section>
		</div>
	</phi-page>
</template>

<script>
import PersonItem from '@/modules/core/components/Person/ListItem.vue';

export default {
	name: "StateClassroom",

	components: {
		PersonItem: PersonItem
	},

	props: {
		personId: {
			default() {
				return this.$store.state.user.id;
			}
		}
	},

	data() {
		return {
			error: null,
			isLoading: false,
            childrens : []
		}
	},

    mounted() {
        this.$store.state.api.get(`v3/people/${this.personId}/relatives/children`)
		.then(data => {

			if(data  && data.length == 1) {
				this.$router.replace( '/people/'+data[0].person.id+'/classroom')
				return;
			}
			this.childrens = data;
		});
    }
}
</script>
